<template>
    <div class="progress-text text-end">
        <h4 :class="textClass()">{{Math.round(percentage)}}%</h4>
        <span v-if="type == 'progress'">{{progressDescription}}</span>
        <span v-if="type == 'marks'">Markah</span>
    </div>
</template>

<script>
export default {
    props: ['percentage', 'type'],
    data() {
        return{
            progressDescription: null
        }
    },
    methods: {
        textClass(){
            switch (true) {
                case (this.percentage >= 0 && this.percentage < 50):
                    return 'few-progress-text'

                case (this.percentage >= 50 && this.percentage < 80):
                    return 'half-progress-text'

                case (this.percentage >= 80 && this.percentage <= 100):
                    return 'complete-progress-text'
            
                default:
                    return ''
            }
        }
    },
    mounted() {
        if(this.percentage >= 0 && this.percentage < 100) this.progressDescription = 'Dalam proses'
        else this.progressDescription = 'Selesai'
    }
}
</script>

<style lang="scss">
@import "./../assets/scss/color.scss";
@import "./../assets/scss/main.scss";

.progress-text {
    margin-top: 15px;

    h4 {
        margin-bottom: 0px;

        &.few-progress-text {
            color: $blue;
        }

        &.half-progress-text {
            color: $yellow;
        }

        &.complete-progress-text {
            color: $green-03;
        }
    }
    span {
        font-weight: 100;
        font-size: 14px;
        color: $gray-light-06;
    }
}

</style>