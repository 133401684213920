<template>
    <div class="progress-circle">
        <div class="box">
            <div class="percent">
                <svg>
                    <circle cx="40" cy="40" r="40"></circle>
                    <circle :style="circleStyle" cx="40" cy="40" r="40"></circle>
                </svg>
                <div class="number">
                    <h3>{{ Math.round(percentage) }}<span>%</span></h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import $ from 'jquery'

export default {
    props: ['percentage'],
    computed: {
        circleStyle(){
            let val = (260 - (260 * this.percentage) / 100)
            return {
                'stroke-dashoffset': val
            }
        }
    },
    mounted(){
        // let val = (260 - (260 * this.percentage) / 100)
        // $('.progress-circle .box .percent svg circle:nth-child(2)').css('stroke-dashoffset', val);
    }    
}
</script>

<style lang="scss">
@import "./../assets/scss/color.scss";
@import "./../assets/scss/main.scss";

.progress-circle {
    // display: flex;
    // justify-content: center;
    // align-items: center;

    .box {
        position: relative;
        width: 100px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // background: #ff000042;

        .percent {
            position: relative;
            width: 100px;
            height: 100px;

            svg {
                position: relative;
                width: 110px;
                height: 110px;
                transform: rotate(270deg);

                circle {
                    width: 100px;
                    height: 100px;
                    fill: none;
                    stroke-width: 7;
                    stroke: #000;
                    transform: translate(5px, 5px);
                    stroke-dasharray: 260;
                    stroke-dashoffset: 260;
                    stroke-linecap: round;

                    &:nth-child(1) {
                        stroke-width: 6;
                        stroke-dashoffset: 0;
                        stroke: $white;
                    }
                    &:nth-child(2) {
                        // stroke-dashoffset: calc(320 - (320 * 67) / 100);
                        // stroke-dashoffset: calc(260 - (260 * 67) / 100);
                        // stroke-dashoffset: 0;
                        stroke: $green;
                    }
                }
            }
            
            .number {
                position: absolute;
                top: 17px;
                left: -2px;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $white;

                h3 {
                    font-size: 22px!important;

                    span {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
</style>